html {
  height: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 16px;
  line-height: 1.15;
}

body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  height: 100%;
  min-height: 400px;
  font-family: 'Roboto Condensed', sans-serif;
  text-align: center;
  background: radial-gradient(circle, #fff 25%, #ccc) #fff;
}

@media (max-width: 400px) {
  html {
    font-size: 4vw;
  }
}

@media (min-width: 1000px) and (min-height: 1000px) {
  html {
    font-size: 1.6vmin;
  }
}
